<template>
  <div class="d-flex">
    <!-- sidebar -->
    <Alert />
    <Sidebar />
    <main class="w-100">
      <Navbar />
      <div style="min-height: calc(100vh - 57px)">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Sidebar from "components/back/Sidebar";
import Navbar from "components/back/Navbar";
import Alert from "components/back/Alert";
export default {
  name: "backLayout",
  components: {
    Sidebar,
    Navbar,
    Alert,
  },
};
</script>
