<template>
  <nav class="backNb active">
    <button class="backNb-toggle" @click="clickToggle">
      <i class="fas fa-bars"></i>
    </button>
    <button class="btn btn-sm ml-auto text-danger" @click="signOut">
      登出
    </button>
  </nav>
</template>
<script>
import { inject } from "vue";
import { useRouter } from "vue-router";
import { logOut } from "connection/admin/auth";
export default {
  name: "Navbar",
  setup() {
    const mitt = inject("mitt");
    const clickToggle = () => {
      mitt.emit("sidebarToggle");
    };
    const router = useRouter();
    const signOut = async () => {
      try {
        await logOut();
        router.push("/");
      } catch (error) {
        console.log(error.message);
      }
    };
    return {
      clickToggle,
      signOut,
    };
  },
};
</script>
