<template>
  <div class="sb text-white bg-dark" :class="{ active: isActive }">
    <div
      class="d-flex justify-content-between justify-content-lg-start align-items-center"
    >
      <router-link
        to="/"
        class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
      >
        <span class="sb-title">全台新後台管理</span>
      </router-link>
      <button
        type="button"
        class="btn text-light d-lg-none"
        @click="isActive = false"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <hr class="bg-light" />
    <ul class="sb-list">
      <li class="sb-list-item" :class="{ active: currentPage === 'admin' }">
        <router-link
          to="/administration/user"
          class="sb-list-item-link"
          aria-current="page"
          @click="currentPage = 'admin'"
        >
          管理者列表
        </router-link>
      </li>
      <li class="sb-list-item" :class="{ active: currentPage === 'category' }">
        <router-link
          to="/administration/category"
          class="sb-list-item-link"
          @click="currentPage = 'category'"
        >
          產品類別
        </router-link>
      </li>
      <li
        class="sb-list-item"
        :class="{ active: currentPage === 'brand_category' }"
      >
        <router-link
          to="/administration/brand_category"
          class="sb-list-item-link"
          @click="currentPage = 'brand_category'"
        >
          廠牌類別
        </router-link>
      </li>
      <li class="sb-list-item" :class="{ active: currentPage === 'product' }">
        <router-link
          to="/administration/product"
          class="sb-list-item-link"
          @click="currentPage = 'product'"
        >
          產品列表
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, inject } from "vue";
export default {
  setup() {
    const currentPage = ref("product");
    const isActive = ref(true);
    const mitt = inject("mitt");
    mitt.on("sidebarToggle", () => {
      isActive.value = !isActive.value;
    });

    return {
      currentPage,
      isActive,
    };
  },
};
</script>
