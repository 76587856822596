<template>
  <div
    v-for="(item, index) in alerts"
    :key="item.timestamp"
    class="alert position-fixed"
    :class="`alert-${item.state}`"
    role="alert"
    :style="`right: 1%; top: calc(10% + ${10 * index}%)`"
  >
    {{ item.message }}
    <button class="btn" @click="close(item.timestamp)">
      <i class="fas fa-times"></i>
    </button>
  </div>
</template>

<script>
import { ref, onMounted, inject, onBeforeMount } from "vue";
export default {
  name: "alert",
  setup() {
    const mitt = inject("mitt");
    const alerts = ref([]);
    const close = (id) => {
      const target = alerts.value.findIndex((item) => item.timestamp === id);
      if (target !== -1) {
        alerts.value.splice(target, 1);
      }
    };
    onMounted(() => {
      mitt.on("alert", ({ state, message }) => {
        const timestamp = Math.floor(new Date());
        const newAlert = { state, message, timestamp };
        alerts.value.push(newAlert);

        setTimeout(() => close(timestamp), 5000);
      });
    });
    onBeforeMount(() => {
      mitt.off("alert");
    });
    return {
      alerts,
      close,
    };
  },
};
</script>
